<div style="background-color: white; border-radius: 4px;">
  <div style="padding: 24px;">
    <div>
      <span style="font-size: 18px; color: #101828;font-weight: 900; line-height: 28px; color: #344054;">
        Reject {{resourceTypeLabel}} application?
      </span>
    </div>
    <div style="margin-top: 24px;">
      <span style="color: #344054; font-weight: 500;">Reason for rejection</span>
    </div>
    <div style="margin-top: 10px;">
      <textarea name="" id="" rows="7" cols="70" style="border-radius: 10px; padding: 10px;"
                [(ngModel)]="rejectionReason" placeholder="Enter your reason here..."></textarea>
    </div>
    <div style="margin-top: 24px;">
      <span style="font-size: 14px; font-weight: 400; line-height: 20px; color: #344054;">
        Once you reject,
        <div class="flex justify-content-start flex-wrap">
          <div class="flex align-items-center justify-content-center  m-2">
            <i class="pi pi-circle-fill" style="font-size: 10px;"></i>
          </div>
          <div class="flex align-items-center justify-content-center  m-2">
            The service provider application will be moved to the &nbsp;
            <span style="color: #101828; font-weight: 700;">reject</span>&nbsp; queue.
          </div>
        </div>
      </span>
    </div>
    <div style="margin-top: 32px;">
      <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
          <button class="btn-1 btn-warning-1 outlined-1 small raised" (click)="onCancel()">Cancel</button>
        </div>
        <div class="flex align-items-center justify-content-center m-2">
          <button class="btn-1 btn-warning-2 outlined-1 small raised" (click)="onReject()">Reject</button>
        </div>
      </div>
    </div>
  </div>
</div>
