import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceProviderService } from '../../Service/Service-providers/service-provider.service';
import { FormStateService } from '../../module/service-provider/add-sp-new-component/form-state.service';
import { Router } from '@angular/router';
import { UserService } from '../../Service/User/user.service';
import { ToastService } from '../../Service/ToastService/toast.service';

@Component({
  selector: 'app-reject-modal',
  templateUrl: './reject-modal.component.html',
  styleUrls: ['./reject-modal.component.scss'],
})
export class RejectModalComponent implements OnInit {
  serviceProviderId: any;
  id!: any;
  serviceAdvisorId!: any;
  rejectionReason: string = '';
  isRejecting: boolean = false;

  @Input() resourceType!: 'USER' | 'SERVICEPROVIDER' | 'SERVICEADVISOR';
  @Input() resourceTypeLabel: string = '';
  @Output() reject = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();
  @Output() onDeleteSuccess = new EventEmitter<any>();
  @Output() updateServiceProviderList: EventEmitter<void> = new EventEmitter<void>();
  @Output() refreshList = new EventEmitter<void>();

  constructor(
    private api: ServiceProviderService,
    private formStateService: FormStateService,
    private router: Router,
    private userAPI: UserService,
    private toastService: ToastService // Inject the ToastService
  ) { }

  ngOnInit(): void {
    this.serviceProviderId = this.formStateService.getServiceProviderId();
    this.id = this.formStateService.getUserId();
    this.serviceAdvisorId = this.formStateService.getServiceAdvisorId();
    console.log(this.serviceAdvisorId, 'Service provider id in reject modal');
  }

  onCancel(): void {
    this.closeModal.emit();
  }

  onReject(): void {
    const data = {
      status: 4,
      message: this.rejectionReason || null,
    };

    switch (this.resourceType) {
      case 'USER':
        this.userAPI.rejectUser(this.id, data).subscribe(
          (res: any) => {
            this.toastService.showToast('Service Seeker Application Rejected', 'success');
            this.reject.emit();
            this.router.navigate(['/service-seekers']);
            this.closeModal.emit();
          },
          (error) => {
            this.toastService.showToast('Failed to reject Service Seeker Application', 'error');
          }
        );
        break;
      case 'SERVICEPROVIDER':
        this.api.approveServiceProvider(this.serviceProviderId, data).subscribe(
          (response: any) => {
            this.toastService.showToast('Service Provider Application Rejected', 'success');
            this.updateServiceProviderList.emit();
            this.router.navigate(['/service-provider']);
            this.closeModal.emit();
            this.refreshList.emit();
          },
          (error: any) => {
            this.toastService.showToast('Failed to reject Service Provider Application', 'error');
            this.isRejecting = false;
          }
        );
        break;
      case 'SERVICEADVISOR':
        this.userAPI.rejectServiceAdvisor(this.serviceAdvisorId, data).subscribe(
          (res: any) => {
            this.toastService.showToast('Service Advisor Application Rejected', 'success');
            this.reject.emit();
            this.router.navigate(['/service-advisors']);
            this.closeModal.emit();
          },
          (error) => {
            this.toastService.showToast('Failed to reject Service Advisor Application', 'error');
            this.isRejecting = false;
          }
        );
        break;
    }
  }
}
