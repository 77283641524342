import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../Service/User/user.service';
import { ServiceProviderService } from '../../Service/Service-providers/service-provider.service';
import { FormStateService } from '../../module/service-provider/add-sp-new-component/form-state.service';
import { ToastService } from '../../Service/ToastService/toast.service';

interface ServiceAdvisor {
  serviceAdvisorId: number;
  name: string;
  city: string;
  state: {
    stateId: number;
    name: string;
  };
  selected?: boolean;
}

@Component({
  selector: 'app-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.scss'],
})
export class ReviewModalComponent implements OnInit {
  @Input() resourceType: string = 'CUSTOMER' || 'SERVICEPROVIDER' || 'SERVICEADVISOR';
  @Output() closeModal = new EventEmitter<void>();
  @Output() onReviewSuccess = new EventEmitter<void>();
  isReviewing = false;
  advisorList: any;
  selectedAdvisor!: ServiceAdvisor;
  @Input() resourceTypeLabel: string = '';

  constructor(
    private userAPI: UserService,
    private serviceProviderAPI: ServiceProviderService,
    private formStateService: FormStateService,
    private router: Router,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    if (this.resourceType === 'SERVICEPROVIDER') {
      this.getAdvisorList();
    }
  }

  onCancel(): void {
    this.closeModal.emit();
  }

  onReview(): void {
    switch (this.resourceType) {
      case 'USER':
        const userData = {
          status: 2,
          message: null,
        };
        this.userAPI.approveUser(this.formStateService.getUserId(), userData).subscribe(
          () => this.handleSuccess('/service-seekers'),
          this.handleError.bind(this)
        );
        break;

      case 'SERVICEPROVIDER':
        const serviceProviderData = {
          serviceAdvisorId: this.selectedAdvisor?.serviceAdvisorId || 0,
        };
        console.log(serviceProviderData);
        this.serviceProviderAPI.assignServiceAdvisor(this.formStateService.getServiceProviderId(), serviceProviderData).subscribe(
          () => this.handleSuccess('/service-provider'),
          this.handleError.bind(this)
        );
        break;

      case 'SERVICEADVISOR':
        const serviceAdvisorData = {
          status: 2,
          message: null,
        };
        this.userAPI.approveServiceAdvisor(this.formStateService.getServiceAdvisorId(), serviceAdvisorData).subscribe(
          () => this.handleSuccess('/service-advisors'),
          this.handleError.bind(this)
        );
        break;
    }
  }
  selectAdvisor(advisor: ServiceAdvisor): void {
    this.selectedAdvisor = advisor;
  }
  isAdvisorSelected(): boolean {
    return this.selectedAdvisor && this.selectedAdvisor.serviceAdvisorId > 0;
  }

  private handleSuccess(navigateTo: string) {
    this.toastService.showToast(`${this.resourceTypeLabel} marked for review successfully`, 'success');
    setTimeout(() => {
      this.onReviewSuccess.emit();
      this.router.navigate([navigateTo]);
      this.closeModal.emit();
    }, 1500);
  }

  private handleError(error: any) {
    this.toastService.showToast(`Failed to mark ${this.resourceTypeLabel} for review`, 'error');
    this.isReviewing = false;
  }

  getAdvisorList() {
    this.serviceProviderAPI.getServiceAdvisors(this.formStateService.getServiceProviderId()).subscribe((res: any) => {
      if (res.result && !Array.isArray(res.result)) {
        this.advisorList = [res.result];
      } else if (Array.isArray(res.result)) {
        this.advisorList = res.result.map((advisor: any) => ({
          ...advisor,
          label: advisor.name, // PrimeNG uses `label` for dropdown text
          value: advisor // Use the whole advisor object as the value
        }));
      } else {
        this.advisorList = [];
      }
      console.log(this.advisorList);
    });
  }
  
  toggleAll(event: any) {
    this.advisorList.forEach((advisor: any) => {
      advisor.selected = event.checked;
    });
  }
}
