import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormStateService } from '../../module/service-provider/add-sp-new-component/form-state.service';
import { ServiceProviderService } from '../../Service/Service-providers/service-provider.service';
import { Router } from '@angular/router';
import { UserService } from '../../Service/User/user.service';
import { MessageService } from 'primeng/api';
import { ToastService } from '../../Service/ToastService/toast.service';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss'],
  providers: [MessageService],
})
export class CustomDialogComponent implements OnInit {
  @Output() closeModal = new EventEmitter<void>();
  @Output() refreshList = new EventEmitter<void>();
  @Input() title!: string;
  @Input() message!: string;
  @Input() details!: string[];
  @Output() onClose = new EventEmitter<void>();
  @Output() onApprove = new EventEmitter<void>();
  @Input() resourceType: string = 'USER' || 'SERVICEPROVIDER' || 'SERVICEADVISOR';

  subdomain!: string;
  isEditingSubdomain: boolean = false;
  serviceProviderId: any;
  serviceAdvisorId: any;
  id: any;
  isApproved: boolean = false;

  constructor(
    private formStateService: FormStateService,
    private api: ServiceProviderService,
    private router: Router,
    private userAPI: UserService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    const retrievedSubdomain = this.formStateService.getSubDomainName() || 'abc';
    this.subdomain = `${retrievedSubdomain}.autoecare`;
    this.serviceProviderId = this.formStateService.getServiceProviderId();
    this.id = this.formStateService.getUserId();
    this.serviceAdvisorId = this.formStateService.getServiceAdvisorId();
  }

  approve() {
    this.isApproved = true;
    const data = {
      status: 3,
      message: '',
    };

    switch (this.resourceType) {
      case 'USER':
        this.userAPI.approveUser(this.id, data).subscribe(
          (res: any) => {
            this.onApprove.emit();
            this.toastService.showToast('Service Seeker Application Approved Successfully', 'success');

            setTimeout(() => {
              this.router.navigate(['/service-seekers']);
              this.closeDialog();
            }, 1500);
          },
          (error) => {
            this.isApproved = false;
            this.toastService.showToast('Failed to approve Service Seeker Application', 'error');
          }
        );
        break;

      case 'SERVICEADVISOR':
        this.userAPI.approveServiceAdvisor(this.serviceAdvisorId, data).subscribe(
          (res: any) => {
            this.onApprove.emit();
            this.toastService.showToast('Service Advisor Application Approved Successfully', 'success');

            setTimeout(() => {
              this.router.navigate(['/service-advisors']);
              this.closeDialog();
            }, 1500);
          },
          (error) => {
            this.isApproved = false;
            this.toastService.showToast('Failed to approve Service Advisor Application', 'error');
          }
        );
        break;

      case 'SERVICEPROVIDER':
        const providerData = {
          ...data,
          subDomain: this.subdomain + '.com',
        };
        this.api.approveServiceProvider(this.serviceProviderId, providerData).subscribe(
          (response: any) => {
            if (response) {
              this.onApprove.emit();
              this.toastService.showToast('Service Provider Application Approved Successfully', 'success');

              setTimeout(() => {
                this.refreshList.emit();
                this.router.navigate(['/service-provider']);
                this.closeDialog();
              }, 1500);
            }
          },
          (error: any) => {
            this.isApproved = false;
            this.toastService.showToast('Failed to approve Service Provider Application', 'error');
          }
        );
        break;

      default:
        console.log('Invalid resource type');
        break;
    }
  }


  edit() {
    console.log('ABC');
  }

  closeDialog() {
    this.closeModal.emit();
  }

  toggleEditSubdomain() {
    this.isEditingSubdomain = !this.isEditingSubdomain;
  }

  saveSubdomain() {
    const trimmedSubdomain = this.subdomain.replace('.autoecare', '');
    this.formStateService.setSubDomainName(trimmedSubdomain);
    this.isEditingSubdomain = false;
    this.subdomain = `${trimmedSubdomain}.autoecare.com`;
  }
}
