<div style="background-color: white; border-radius: 4px;">
  <div style="padding: 24px;">
    <div>
      <span style="font-size: 18px; font-weight: 900; line-height: 28px; color: #344054;">
        Mark {{ resourceTypeLabel }} for review?
      </span>
    </div>

    <div *ngIf="resourceType === 'SERVICEPROVIDER'" style="margin-top: 14px;">
      <span style="font-size: 16px; font-weight: 500; line-height: 20px; color: #344054;">
        Please select your nearest Service Advisor for review:
      </span>
      <div class="advisor-dropdown mt-3">
        <p-dropdown [options]="advisorList" [(ngModel)]="selectedAdvisor" optionLabel="name"
          placeholder="Select an Advisor" [disabled]="isReviewing">
        </p-dropdown>
      </div>
    </div>

    <div style="margin-top: 32px;">
      <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
          <button class="btn-1 btn-warning-1 outlined-1 small raised" (click)="onCancel()"
            [disabled]="isReviewing">Cancel</button>
        </div>
        <div class="flex align-items-center justify-content-center m-2">
          <button class="btn-1 btn-warning-2 outlined-1 small raised" (click)="onReview()">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</div>