<ng-container *ngIf="resourceType !== 'SERVICEPROVIDER'; else serviceProviderTemplate">
    <div style="background-color: white; border-radius: 4px;">
        <div style="padding: 24px;">
            <div>
                <span style="font-size: 18px; color: #101828;font-weight: 900; line-height: 28px; color: #344054;">
                    {{ title }}
                </span>
            </div>

            <div style="margin-top: 24px;">
                <span style="font-size: 14px; font-weight: 400; line-height: 20px; color: #344054;">
                    {{ message }}
                </span>
                <div class="flex justify-content-start flex-wrap" *ngFor="let detail of details">
                    <div class="flex align-items-center justify-content-center m-2">
                        <i class="pi pi-circle-fill" style="font-size: 10px;"></i>
                    </div>
                    <div class="flex align-items-center justify-content-center m-2">
                        <span [innerHTML]="detail"></span>
                    </div>
                </div>
            </div>
            <div style="margin-top: 32px;">
                <div class="flex justify-content-end flex-wrap">
                    <div class="flex align-items-center justify-content-center m-2">
                        <button class="btn-1 btn-warning-1 outlined-1 small raised"
                            (click)="closeDialog()">Cancel</button>
                    </div>
                    <div class="flex align-items-center justify-content-center m-2">
                        <button class="btn-1 btn-seconary-warning small raised" (click)="approve()">Approve</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- Template for Service Provider Dialog -->
<ng-template #serviceProviderTemplate>
    <div class="dialog-container">
        <div class="dialog-header">
            <span class="dialog-title">Approve service provider application?</span>
        </div>

        <div class="dialog-body">
            <p class="dialog-message">Once you approve,</p>
            <ul class="dialog-details">
                <li class="dialog-detail-item">
                    <i class="pi pi-circle-fill dialog-icon"></i>
                    <span class="dialog-detail-text">The service provider application will be moved to the
                        <strong>Approved</strong> queue.</span>
                </li>
                <li class="dialog-detail-item">
                    <i class="pi pi-circle-fill dialog-icon"></i>
                    <span class="dialog-detail-text">
                        Once approved, you will be provided
                        <input type="text" [(ngModel)]="subdomain" class="subdomain-input" />
                        .com
                    </span>
                </li>
            </ul>
        </div>

        <div class="dialog-footer">
            <button class="btn btn-cancel" [disabled]="isApproved" (click)="closeDialog()">Cancel</button>
            <button class="btn btn-approve" [disabled]="isApproved" (click)="approve()">Approve</button>
        </div>
    </div>
</ng-template>